var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-modify-delete-eticket",
      "title": "Xoá số vé",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.handleModalOpen
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mt-lg-25  border-0",
          attrs: {
            "variant": "outline-danger",
            "disabled": _vm.isEmpty(_vm.indexSelectedTicketToDel),
            "pill": ""
          },
          on: {
            "click": _vm.submitDeleteTicket
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v("Xoá số vé")])])];
      }
    }])
  }, [_c('div', {
    staticClass: "text-heading-4"
  }, [_vm._v(" Chọn số vé: ")]), _c('div', {
    staticClass: "custom-label-checkbox"
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          staticClass: "py-0 pt-1",
          attrs: {
            "aria-describedby": "flights",
            "aria-controls": "flights"
          },
          on: {
            "change": _vm.toggleAll
          },
          model: {
            value: _vm.allSelected,
            callback: function callback($$v) {
              _vm.allSelected = $$v;
            },
            expression: "allSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2 font-weight-bold",
          staticStyle: {
            "padding-bottom": "13px"
          }
        }, [_vm._v(" " + _vm._s(_vm.allSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "flights"
          },
          model: {
            value: _vm.indexSelectedTicketToDel,
            callback: function callback($$v) {
              _vm.indexSelectedTicketToDel = $$v;
            },
            expression: "indexSelectedTicketToDel"
          }
        }, _vm._l(_vm.ticketList, function (ticket, indexTicket) {
          return _c('div', {
            key: indexTicket,
            staticClass: "d-flex-center justify-content-start py-0 pb-50"
          }, [_c('b-form-checkbox', {
            attrs: {
              "value": ticket.id
            }
          }, [_c('div', {
            staticClass: "d-flex-center text-dark font-weight-bold font-medium-1"
          }, [_c('span', {
            staticClass: "text-warning font-weight-bolder mr-25"
          }, [_vm._v(_vm._s(ticket.number))]), _c('span', {
            staticClass: "font-weight-bolder"
          }, [_vm._v(" (" + _vm._s(ticket.passengerPaxId) + ") " + _vm._s(ticket.passengerName) + " - " + _vm._s(ticket.ticketType) + " - " + _vm._s(ticket.status) + " ")])])])], 1);
        }), 0)];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }