<template>
  <b-modal
    id="modal-modify-delete-eticket"
    title="Xoá số vé"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    size="lg"
    no-close-on-backdrop
    @show="handleModalOpen"
  >
    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="mt-lg-25  border-0"
        variant="outline-danger"
        :disabled="isEmpty(indexSelectedTicketToDel)"
        pill
        @click="submitDeleteTicket"
      >
        <span class="align-middle">Xoá số vé</span>
      </b-button>
    </template>

    <div class="text-heading-4">
      Chọn số vé:
    </div>
    <div class="custom-label-checkbox">
      <b-form-group>
        <template #label>
          <b-form-checkbox
            v-model="allSelected"
            aria-describedby="flights"
            aria-controls="flights"
            class="py-0 pt-1"
            @change="toggleAll"
          >
            <div
              class="text-body-2 font-weight-bold"
              style="padding-bottom: 13px"
            >
              {{ allSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
            </div>
          </b-form-checkbox>
        </template>

        <template v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="indexSelectedTicketToDel"
            :aria-describedby="ariaDescribedby"
            stacked
            name="flights"
          >
            <div
              v-for="(ticket, indexTicket) in ticketList"
              :key="indexTicket"
              class="d-flex-center justify-content-start py-0 pb-50"
            >
              <!-- :disabled="ticket.status !== 'OK'" -->
              <b-form-checkbox
                :value="ticket.id"
              >
                <div class="d-flex-center text-dark font-weight-bold font-medium-1">
                  <span class="text-warning font-weight-bolder mr-25">{{ ticket.number }}</span>
                  <span class="font-weight-bolder">
                    ({{ ticket.passengerPaxId }}) {{ ticket.passengerName }} - {{ ticket.ticketType }} - {{ ticket.status }}
                  </span>
                </div>
              </b-form-checkbox>
            </div>
          </b-form-checkbox-group>
        </template>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import {
  ref, watch, toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BButton,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
    ticketList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { toastError, toastSuccess } = useToast()
    const { eTicketUpdates } = useReservationHandle()

    const ticketList = toRefs(props).ticketList
    const getBookingData = toRefs(props).bookingData

    const indexSelectedTicketToDel = ref([])

    function submitDeleteTicket(bvModalEvent) {
      bvModalEvent.preventDefault()

      const payload = indexSelectedTicketToDel.value.map(i => ({
        id: String(i),
        operation: 'DELETE',
      }))

      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: '<p> Xác nhận xoá số vé đã chọn? </p>' },
      })

      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Xoá số vé',
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            eTicketUpdates(getBookingData.value, payload)
              .then(() => {
                toastSuccess({
                  title: 'messagesList.success',
                  content: 'Xoá số vé thành công!',
                })
              })
              .catch(() => {
                toastError({
                  title: 'messagesList.error',
                  content: 'Có lỗi xảy ra khi xoá số vé, vui lòng kiểm tra lại!',
                })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    // ANCHOR SELECT ALL
    const allSelected = ref(false)

    function toggleAll(checked) {
      indexSelectedTicketToDel.value = checked
        ? ticketList.value.map(item => item.id).slice() : []
    }

    watch(indexSelectedTicketToDel, newValue => {
      if (newValue.length === 0) {
        allSelected.value = false
      } else if (newValue.length === ticketList.value.map(item => item.id).length) {
        allSelected.value = true
      } else {
        allSelected.value = false
      }
    })

    function handleModalOpen() {
      indexSelectedTicketToDel.value = []
    }
    return {
      getBookingData,
      submitDeleteTicket,
      indexSelectedTicketToDel,
      allSelected,
      isEmpty,
      toggleAll,
      handleModalOpen,
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-label-checkbox::v-deep {
  .custom-control-label {
    margin-top: 2px !important;
  }
}
</style>
